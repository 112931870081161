/* Reset default styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#floatleft {
  float: left;
  margin-top: 100px;
  padding: 20px;
}


/* Global styles */
body {
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2.5rem;
  margin: 1rem 0;
  text-align: center;
}

/* Player styles */
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* YouTube styles */
.App {
  background-color: #f9f9f9;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}


@media (min-width: 640px) {
  .App {
    padding: 0 2rem;
  }
}

@media (min-width: 1024px) {
  .App {
    padding: 0 4rem;
  }
}